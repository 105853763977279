import React from 'react'

import Layout from '../components/layout'
import Meta from '../components/meta'
import {Link} from 'gatsby'

const ContactUsSuccessPage = () => (
  <>
    <Meta
      title="Message Was Sent Successfully"
    />
    <Layout>
      <div className="typography-page limiter short-page">
        <h2>
          Your message was sent successfully!
        </h2>
        <p>
          We'll do our best to get back to you as soon as possible. <br/><br/>
          <Link to="/">Go to the Homepage?</Link>
        </p>
      </div>
    </Layout>
  </>
)

export default ContactUsSuccessPage
